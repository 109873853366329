import { DefaultTheme } from "styled-components";

const myTheme: DefaultTheme = {
    color: {
        green: "#315A50",
        cream: "#FFE8D1",
        white: "#FFFFFF",
    },
    fontSize: {
        normal: "15px",
    },
    fontWeight: {
        thin: "400",
        normal: "500",
        bold: "600",
    },
};

export { myTheme };
