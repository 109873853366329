import React from "react";
import { ThemeProvider } from "styled-components";
import { myTheme } from "./src/MyTheme.ts";

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={myTheme}>
        <div id="fb-root"></div>
        <div id="fb-customer-chat" class="fb-customerchat"></div>
        {element}
    </ThemeProvider>
);
